<template>
  <div class="sidebar-wrapper">
    <div
      class="sidebar-container"
      data-t="sidebar"
      @mouseleave="hasHover = false"
      @mouseover="hasHover = true"
    >
      <slot />
    </div>
  </div>
</template>

<script lang="ts" setup>
const hasHover = ref(false)

const { width } = useWindowSize()

const isCompact = ref(false)
const isMounted = ref(false)

provide('isCompact', isCompact)

watchEffect(() => {
  if (!isMounted.value) return

  isCompact.value = width.value <= 1440 && !hasHover.value
})

onMounted(async () => {
  await nextTick()
  isMounted.value = true
  isCompact.value = width.value <= 1440 && !hasHover.value
})
</script>

<style scoped>
.sidebar-wrapper {
  --sidebar-full-width: 240px;
  --sidebar-compact-width: 48px;

  position: relative;
  z-index: 1;
  width: var(--sidebar-full-width);
  height: 100%;

  @media screen and (width <= 1440px) {
    width: var(--sidebar-compact-width);
  }
}

.sidebar-container {
  position: absolute;
  inset: 0;

  overflow: hidden;

  width: var(--sidebar-full-width);

  transition: width 0.2s cubic-bezier(0.28, 0.11, 0.32, 1);

  @media screen and (width <= 1440px) {
    width: var(--sidebar-compact-width);

    &:hover {
      width: var(--sidebar-full-width);
      filter: drop-shadow(4px 0 8px rgb(17 17 24 / 64%))
        drop-shadow(-4px 0 8px rgb(17 17 24 / 64%))
        drop-shadow(0 -4px 8px rgb(17 17 24 / 64%))
        drop-shadow(0 4px 8px rgb(17 17 24 / 64%));
    }
  }
}
</style>
