<template>
  <div :class="classes">
    <slot />
  </div>
</template>

<script setup lang="ts">
import type { Ref } from 'vue'

const props = defineProps<{
  level: number
}>()

const isCompact = inject<Ref<boolean>>('isCompact')

const classes = computed(() => [
  'sidebar-list',
  `level-${props.level}`,
  { compact: isCompact?.value },
])
</script>

<style scoped>
.sidebar-list {
  display: flex;
  flex-direction: column;

  &.level-1 {
    gap: var(--spacing-025);
    padding: var(--spacing-050);
    transition: padding 0.2s;

    &.compact {
      padding: var(--spacing-050);
    }
  }

  &.level-2 {
    gap: var(--spacing-025);
    padding: var(--spacing-025);
    border-top: 1px solid var(--border-secondary);
  }

  &.level-3 {
    gap: var(--spacing-025);
    padding-block: var(--spacing-025);
    background-color: var(--background-secondary);
    border-radius: var(--border-radius-075, 6px);
  }

  &.level-4 {
    padding: var(--spacing-025);
    border-top: 1px solid var(--border-secondary);
  }
}
</style>
